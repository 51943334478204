import React, { Component } from 'react'
import { MDBCollapse, MDBCol, MDBRow, MDBContainer } from 'mdbreact'

class CollapsePage extends Component {
  state = {
    collapseID: '',
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : collapseID,
    }))
  }

  toggleActive = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : collapseID,
    }))
  }

  render() {
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12">
            <h3 className="font-alt font-w-700 mt-5 mb-4 letter-spacing-1 title-xs-medium title-medium text-uppercase">
              The Americas
            </h3>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'beverly-hills' ? 'location' : 'location-link'}`} 
                  onClick={this.toggleCollapse('beverly-hills')}
                >
                  Beverly Hills, CA
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'mission-hills' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('mission-hills')}
                >
                  Mission Hills, CA
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'schaumburg' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('schaumburg')}
                >
                  Schaumburg, IL
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'beverly-hills' || this.state.collapseID == 'mission-hills' || this.state.collapseID == 'schaumburg') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="beverly-hills"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS, INC.
                    <br />
                    <span className="font-w-400 text-medium">
                      1143 Summit Drive
                      <br />
                      Beverly Hills, CA 90210
                      <br />
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/34.09043,-118.419271"
                        target="_blank"
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    PICKFAIR
                    <br />
                    <span className="font-w-400 text-medium">
                      1143 Summit Drive
                      <br />
                      Beverly Hills, CA 90210
                      <br />
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br />
                      <a href="https://www.pickfair.com" target="_blank" className="effect">
                        https://www.pickfair.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/34.09043,-118.419271"
                        target="_blank"
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="mission-hills"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GLOBAL HQ
                    <br />
                    <span className="font-w-400 text-medium">
                      UNICOM Plaza Suite 310
                      <br />
                      15535 San Fernando Mission Blvd.
                      <br />
                      Mission Hills, CA 91345
                      <br/>
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br />
                      <a href="https://www.unicomglobal.com" target="_blank" className="effect">
                        https://www.unicomglobal.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/34.272079,-118.470734"
                        target="_blank"
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="schaumburg"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
              <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM INNOVATION PARK
                    <br />
                    <span className="font-w-400 text-medium">
                      231 N. Martingale Road
                      <br />
                      Schaumburg, IL 60173
                      <br />
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br /><br />
                      <a href="https://www.unicom.org.com" target="_blank" className="effect">
                        https://www.unicom.org
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/42.03334,-88.03274"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    U.S. ROBOTICS CORPORATION
                    <br />
                    <span className="font-w-400 text-medium">
                      231 N. Martingale Road
                      <br />
                      Schaumburg, IL 60173
                      <br />
                      United States
                      <br />
                      T: +1 847.874.2000
                      <br />
                      F: +1 847.874.2001
                      <br /><br />
                      <a href="https://www.usr.com" target="_blank" className="effect">
                        https://www.usr.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/42.043706,-88.046169"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'campbell' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('campbell')}
                >
                  Campbell, CA
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'peterborough' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('peterborough')}
                >
                  Peterborough, NH
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'westminster' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('westminster')}
                >
                  Westminster, CO
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'campbell' || this.state.collapseID == 'peterborough' || this.state.collapseID == 'westminster') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="campbell"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS SILICON VALLEY
                    <br />
                    <span className="font-w-400 text-medium">
                      900 E. Hamilton Plaza STE 100
                      <br />
                      Campbell CA 95008
                      <br />
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/37.293861,-121.9345"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MEMEO
                    <br />
                    <span className="font-w-400 text-medium">
                      900 E. Hamilton Plaza STE 100
                      <br />
                      Campbell CA 95008
                      <br />
                      United States
                      <br />
                      T: +1 408.834.7168
                      <br />
                      F: +1 408.453.3151
                      <br /><br />
                      <a href="https://www.memeo.com" target="_blank" className="effect">
                        https://www.memeo.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/37.293861,-121.9345"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="peterborough"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    SOFTLANDING SYSTEMS, INC.
                    <br />
                    <span className="font-w-400 text-medium">
                      1 Vose Farm Road
                      <br />
                      Suite 110
                      <br />
                      Peterborough, NH 03458
                      <br />
                      United States
                      <br />
                      T: +1 603.924.8818
                      <br />
                      F: +1 603.924.6348
                      <br /><br />
                      <a href="https://www.softlanding.com" target="_blank" className="effect">
                        https://www.softanding.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/42.915603167345544,-71.93801556906577"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="westminster"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS, INC.
                    <br />
                    <span className="font-w-400 text-medium">
                      1333 W. 120th Ave. STE 210
                      <br />
                      Westminster, CO 80234
                      <br />
                      United States
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/39.914639,-105.002361"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'canton' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('canton')}
                >
                  Canton, MA
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'plainsboro' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('plainsboro')}
                >
                  Plainsboro, NJ
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'whitehouse-station' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('whitehouse-station')}
                >
                  Whitehouse Station, NJ
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'canton' || this.state.collapseID == 'plainsboro' || this.state.collapseID == 'whitehouse-station') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="canton"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM ENGINEERING, INC.
                    <br />
                    <span className="font-w-400 text-medium">
                      25 Dan Road
                      <br />
                      Canton, MA 02021
                      <br />
                      United States
                      <br />
                      T: +1 781.332.1000
                      <br />
                      F: +1 781.770.2000
                      <br /><br />
                      <a
                        href="https://www.unicomengineering.com"
                        target="_blank" 
                        className="effect"
                      >
                        https://www.unicomengineering.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/42.159774,-71.112243"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    IET SOLUTIONS, LLC.
                    <br />
                    <span className="font-w-400 text-medium">
                      25 Dan Road
                      <br />
                      Canton, MA 02021
                      <br />
                      United States
                      <br />
                      T: +1 508.270.7000
                      <br />
                      F: +1 508.416.9008
                      <br /><br />
                      <a href="https://www.iet-solutions.com" target="_blank" className="effect">
                        https://www.iet-solutions.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/42.159774,-71.112243"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="plainsboro"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS, INC.
                    <br />
                    <span className="font-w-400 text-medium">
                      101 Morgan Lane
                      <br />
                      Plainsboro, NJ 08536
                      <br />
                      United States
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/40.331031,-74.585219"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="whitehouse-station"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SCIENCE &amp; TECHNOLOGY PARK
                    <br />
                    <span className="font-w-400 text-medium">
                      3 Merck Drive
                      <br />
                      Whitehouse Station, NJ 08889
                      <br />
                      United States
                      <br />
                      T: +1 973.526.3900
                      <br />
                      F: +1 973.526.3899
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/40.640763,-74.773795"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'chantilly' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('chantilly')}
                >
                  Chantilly, VA
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'plano' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('plano')}
                >
                  Plano, TX
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'chantilly' || this.state.collapseID == 'plano') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="chantilly"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM TECHNOLOGY PARK I &amp; II
                    <br />
                    <span className="font-w-400 text-medium">
                      15000 Conference Center Drive
                      <br />
                      Chantilly, VA 20151
                      <br />
                      United States
                      <br />
                      T: +1 818.838.0606
                      <br />
                      F: +1 818.838.0776
                      <br /><br />
                      <a href="https://www.unicom.org" target="_blank" className="effect">
                        https://www.unicom.org
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/38.875132,-77.465706"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GOVERNMENT
                    <br />
                    <span className="font-w-400 text-medium">
                      15010 Conference Center Drive
                      <br />
                      Chantilly, VA 20151
                      <br />
                      United States
                      <br />
                      T: +1 703.502.2000
                      <br />
                      F: +1 703.463.5011
                      <br /><br />
                      <a href="https://www.unicomgov.com" target="_blank" className="effect">
                        https://www.unicomgov.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/38.875132,-77.465706"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GOVERNMENT DISTRIBUTION CENTER
                    <br />
                    <span className="font-w-400 text-medium">
                      3900 Stonecroft Blvd
                      <br />
                      Bldg 3900-A
                      <br />
                      Chantilly, VA 20151
                      <br />
                      United States
                      <br /><br />
                      <a href="https://www.unicomgov.com" target="_blank" className="effect">
                        https://www.unicomgov.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/38.875132,-77.465706"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="plano"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GLOBAL DEVELOPMENT CENTER
                    <br />
                    <span className="font-w-400 text-medium">
                      3501 East Plano Parkway
                      <br />
                      Plano, TX 75074
                      <br />
                      United States
                      <br />
                      T: +1 972.633.3400
                      <br />
                      F: +1 972.633.3499
                      <br /><br />
                      <a href="https://www.unicomglobal.com" target="_blank" className="effect">
                        https://www.unicomglobal.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/33.009591,-96.666182"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'greensboro' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('greensboro')}
                >
                  Greensboro, NC
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'raleigh' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('raleigh')}
                >
                  Raleigh, NC
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'greensboro' || this.state.collapseID == 'raleigh') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="greensboro"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS GREENSBORO
                    <br />
                    <span className="font-w-400 text-medium">
                      4249 Piedmont Pkwy, Suite 103
                      <br />
                      Greensboro, NC 27410
                      <br />
                      United States
                      <br />
                      T: +1 336.605.6200
                      <br />
                      F: +1 336.605.6201
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/36.056454,-79.944201"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="raleigh"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS RALEIGH
                    <br />
                    <span className="font-w-400 text-medium">
                      4700 Homewood Court, Suite 340
                      <br />
                      Raleigh, NC 27609
                      <br />
                      United States
                      <br />
                      T: +1 919.876.6807
                      <br />
                      F: +1 919.800.3529
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/35.84486191429957,-78.6419829602509"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12">
            <h3 className="font-alt font-w-700 mt-5 mb-4 letter-spacing-1 title-xs-medium title-medium text-uppercase">
              Europe, Middle East &amp; Africa
            </h3>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'amsterdam' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('amsterdam')}
                >
                  Amsterdam, Netherlands
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'galway' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('galway')}
                >
                  Galway, Ireland
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'mechelen' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('mechelen')}
                >
                  Mechelen, Belgium
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'amsterdam' || this.state.collapseID == 'galway' || this.state.collapseID == 'mechelen') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="amsterdam"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 BENELUX N.V. - NETHERLANDS
                    <br />
                    <span className="font-w-400 text-medium">
                      Keizersgracht 62-64
                      <br />
                      1015 CS Amsterdam
                      <br />
                      The Netherlands
                      <br />
                      T: +31 205 206 874
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/52.378225,4.888103"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="galway"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM ENGINEERING EMEA DEVELOPMENT CENTER
                    <br />
                    <span className="font-w-400 text-medium">
                      Parkmore West Business Park
                      <br />
                      Galway
                      <br />
                      Ireland
                      <br />
                      T: +353 91 381550
                      <br />
                      F: +353 91 757548
                      <br /><br />
                      <a
                        href="https://www.unicomengineering.com"
                        target="_blank" 
                        className="effect"
                      >
                        https://www.unicomengineering.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/53.338294,-8.202618"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="mechelen"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 (BENELUX) - BELGIUM
                    <br />
                    <span className="font-w-400 text-medium">
                      pa Bopro Business Center
                      <br />
                      Zandvoortstraat C27/100
                      <br />
                      2800 Mechelen
                      <br />
                      Belgium
                      <br />
                      T: +32 15 747480
                      <br />
                      F: +32 15 747489
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/51.054485,4.443903"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'aschheim' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('aschheim')}
                >
                  Aschheim, Germany
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'g-gerau' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('g-gerau')}
                >
                  Groß-Gerau, Germany
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'milano' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('milano')}
                >
                  Milano, Italy
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'aschheim' || this.state.collapseID == 'g-gerau' || this.state.collapseID == 'milano') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="aschheim"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 GERMANY
                    <br />
                    <span className="font-w-400 text-medium">
                      Humboldtstr. 10
                      <br />
                      85609 Aschheim
                      <br />
                      Germany
                      <br />
                      T: +49 89 6100970
                      <br />
                      F: +49 89 61009777
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/48.151298,11.682698"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    IET SOLUTIONS EUROPE
                    <br />
                    <span className="font-w-400 text-medium">
                      Humboldtstr. 10
                      <br />
                      85609 Aschheim
                      <br />
                      Germany
                      <br />
                      T: +49 89 7485890
                      <br />
                      F: +49 89 74858920
                      <br /><br />
                      <a href="https://www.iet-solutions.com" target="_blank" className="effect">
                        https://www.iet-solutions.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/48.151298,11.682698"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    U.S. ROBOTICS GMBH
                    <br />
                    <span className="font-w-400 text-medium">
                      Humboldtstr. 10
                      <br />
                      85609 Aschheim
                      <br />
                      Germany
                      <br />
                      T: +49 89 6100970
                      <br />
                      F: +49 89 61009777
                      <br /><br />
                      <a href="https://www.usr.com" target="_blank" className="effect">
                        https://www.usr.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/48.151298,11.682698"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="g-gerau"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    DETEC SOFTWARE GMBH
                    <br />
                    <span className="font-w-400 text-medium">
                      Im Neugrund 16
                      <br />
                      64521 Groß-Gerau
                      <br />
                      Germany
                      <br />
                      T: +49 6152 7123 0
                      <br />
                      F: +49 6152 7123 199
                      <br /><br />
                      <a href="https://www.detec.com" target="_blank" className="effect">
                        https://www.detec.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/49.933007,8.488921"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="milano"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GLOBAL ITALIA S.R.L.
                    <br />
                    <span className="font-w-400 text-medium">
                      Via Milano, 37/C
                      <br />
                      20064 Gorgonzola
                      <br />
                      Milano
                      <br />
                      Italy
                      <br />
                      T: +39 2 2131941
                      <br />
                      F: +39 2 2139611
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/45.5264917,9.3956402"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'billancourt' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('billancourt')}
                >
                  Boulogne Billancourt, France
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'helsinki' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('helsinki')}
                >
                  Helsinki, Finland
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'thalwil' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('thalwil')}
                >
                  Thalwil, Switzerland
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'billancourt' || this.state.collapseID == 'helsinki' || this.state.collapseID == 'thalwil') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="billancourt"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 FRANCE
                    <br />
                    <span className="font-w-400 text-medium">
                      88, avenue du Général LECLERC
                      <br />
                      92100 Boulogne Billancourt
                      <br />
                      France
                      <br />
                      T: +33 1 79718450
                      <br />
                      F: +33 1 41033201
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/48.831576,2.236011"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="helsinki"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 LIMITED, SIVULIIKE SUOMESSA (FINLAND)
                    <br />
                    <span className="font-w-400 text-medium">
                      Heikkilantie 7
                      <br />
                      00210 Helsinki
                      <br />
                      Finland
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/60.156010,24.884307"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="thalwil"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 SWITZERLAND
                    <br />
                    <span className="font-w-400 text-medium">
                      Zürcherstrasse 61
                      <br />
                      CH-8800 Thalwil
                      <br />
                      Switzerland
                      <br />
                      T: +41 44 723 40 00
                      <br />
                      F: +41 44 723 40 02
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/47.284374,8.566348"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'coventry' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('coventry')}
                >
                  Coventry, United Kingdom
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'manchester' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('manchester')}
                >
                  Manchester, United Kingdom
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'coventry' || this.state.collapseID == 'manchester') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="coventry"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 MIDLANDS OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      Unit 3, Elm court
                      <br />
                      Meriden Business Park
                      <br />
                      Copse Drive
                      <br />
                      Coventry, CV5 9RG
                      <br />
                      United Kingdom
                      <br />
                      T: +44 1676 524430
                      <br />
                      F: +44 1676 524431
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/52.433959,-1.604678"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="manchester"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 MANCHESTER OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      St John’s House (6th Floor)
                      <br />
                      2-10 Queen Street
                      <br />
                      Manchester, M2 5JB
                      <br />
                      United Kingdom
                      <br />
                      T: +44 161 817 2600
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/53.479611,-2.248159"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'crawley' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('crawley')}
                >
                  Crawley, United Kingdom
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'madrid' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('madrid')}
                >
                  Madrid, Spain
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'crawley' || this.state.collapseID == 'madrid') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="crawley"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    MACRO 4 LIMITED
                    <br />
                    <span className="font-w-400 text-medium">
                      The Orangery
                      <br />
                      Turners Hill Road
                      <br />
                      Worth, Crawley
                      <br />
                      West Sussex, RH10 4SS
                      <br />
                      United Kingdom
                      <br />
                      T: +44 1293 872000
                      <br />
                      F: +44 1293 872001
                      <br /><br />
                      <a href="https://www.macro4.com" target="_blank" className="effect">
                        https://www.macro4.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/51.120943,-0.136095"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    SOFTLANDING EUROPE
                    <br />
                    <span className="font-w-400 text-medium">
                      The Orangery
                      <br />
                      Turners Hill Road
                      <br />
                      Worth, Crawley
                      <br />
                      West Sussex, RH10 4SS
                      <br />
                      United Kingdom
                      <br />
                      T: +44 1293 872011
                      <br />
                      F: +44 1293 872004
                      <br /><br />
                      <a href="https://www.softlanding.com" target="_blank" className="effect">
                        https://www.softlanding.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/51.120943,-0.136095"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>

                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    USROBOTICS UK LTD
                    <br />
                    <span className="font-w-400 text-medium">
                      The Orangery
                      <br />
                      Turners Hill Road
                      <br />
                      Worth, Crawley
                      <br />
                      West Sussex, RH10 4SS
                      <br />
                      United Kingdom
                      <br />
                      T: +44 1293 872316
                      <br /><br /><br />
                      <a href="https://www.usr.com" target="_blank" className="effect">
                        https://www.usr.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/51.120943,-0.136095"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="madrid"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM GLOBAL IBERIA, S.A.
                    <br />
                    <span className="font-w-400 text-medium">
                      Calle Tizona, 1, planta 3
                      <br />
                      28220, Majadahonda
                      <br />
                      Madrid
                      <br />
                      Spain
                      <br />
                      T: +34 914 430220
                      <br />
                      F: +34 916 385589
                      <br /><br />
                      <a href="https://www.unicomglobal.com" target="_blank" className="effect">
                        https://www.unicomglobal.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/40.470717,-3.870736"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12">
            <h3 className="font-alt font-w-700 mt-5 mb-4 letter-spacing-1 title-xs-medium title-medium text-uppercase">
              Asia Pacific
            </h3>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'bangalore' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('bangalore')}
                >
                  Bangalore, India
                </a>
              </MDBCol>

              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'north-ryde' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('north-ryde')}
                >
                  North Ryde, Australia
                </a>
              </MDBCol>


              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'xian' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('xian')}
                >
                  Xi'an, China
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'bangalore' || this.state.collapseID == 'north-ryde' || this.state.collapseID == 'xian') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="bangalore"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    FIRETIDE INDIA R&amp;D OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      Vaishnavi Classic, No.37, 1st Floor
                      <br />
                      Kasturba Road Cross
                      <br />
                      Off Lavelle Road
                      <br />
                      Bangalore – 560001
                      <br />
                      India
                      <br /><br />
                      <a href="https://www.firetide.com" target="_blank" className="effect">
                        https://www.firetide.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/12.973655,77.599133"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="north-ryde"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS AUSTRALIA
                    <br />
                    <span className="font-w-400 text-medium">
                      Level 5, 7 Eden Park Drive
                      <br />
                      Macquarie Park, North Ryde NSW
                      <br />
                      2113, Australia
                      <br />
                      T: +61 (0)2 8935 9488
                      <br />
                      F: +61 (0)2 8935 9401
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/-33.786618,151.130947"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>




            <MDBCollapse
              className="mt-4"
              id="xian"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS XI'AN OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      Room 611, Building A
                      <br />
                      GaoKeShangDo one ShangCheng
                      <br />
                      No 41 of ZhangBa No 5 Road
                      <br />
                      Gaoxin District, Xi'an
                      <br />
                      ShaanXi
                      <br />
                      PRC
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/34.195694,108.864194"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="12" className="py-3">
            <MDBRow>
              <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'beijing' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('beijing')}
                >
                  Beijing, China
                </a>
              </MDBCol>

             <MDBCol md="4" className="location-col font-w-400 text-md-small">
                <a
                  className={`effect ${this.state.collapseID == 'singapore' ? 'location' : 'location-link'}`}
                  onClick={this.toggleCollapse('singapore')}
                >
                  Singapore
                </a>
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol
            md="12"
            className={(this.state.collapseID == 'beijing' || this.state.collapseID == 'singapore') ? 'location-panel' : ''}
          >
            <MDBCollapse
              className="mt-4"
              id="beijing"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS BEIJING OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      2F, Building 29B (Lingzhi Center)
                      <br />
                      Ao Bei Science and Technology Park
                      <br />
                      Beijing 100192
                      <br />
                      PRC
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/40.039259,116.383573"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>

            <MDBCollapse
              className="mt-4"
              id="singapore"
              isOpen={this.state.collapseID}
            >
              <MDBRow>
                <MDBCol md="4">
                  <p className="font-w-700 text-medium">
                    UNICOM SYSTEMS SINGAPORE OFFICE
                    <br />
                    <span className="font-w-400 text-medium">
                      9 Raffles Place
                      <br />
                      #19-21 Republic Plaza
                      <br />
                      Singapore 048619
                      <br /><br />
                      <a href="https://www.unicomsi.com" target="_blank" className="effect">
                        https://www.unicomsi.com
                      </a>
                      <br />
                      <a
                        href="https://www.google.com/maps/dir/Current+Location/1.2833067094589194,103.85089958603821"
                        target="_blank" 
                        className="effect"
                      >
                        Google Directions
                      </a>
                    </span>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default CollapsePage